.impressum_container {
    font-family: "SpecialEliteRegular";
    color: #E0DDCF;
  text-shadow: -2px   -2px #362e41,
  -2px -1.5px #362e41,
  -2px   -1px #362e41,
  -2px -0.5px #362e41,
  -2px    0px #362e41,
  -2px  0.5px #362e41,
  -2px    1px #362e41,
  -2px  1.5px #362e41,
  -2px    2px #362e41,
  -1.5px  2px #362e41,
  -1px    2px #362e41,
  -0.5px  2px #362e41,
  0px     2px #362e41,
  0.5px   2px #362e41,
  1px     2px #362e41,
  1.5px   2px #362e41,
  2px     2px #362e41,
  2px   1.5px #362e41,
  2px     1px #362e41,
  2px   0.5px #362e41,
  2px     0px #362e41,
  2px  -0.5px #362e41,
  2px    -1px #362e41,
  2px  -1.5px #362e41,
  2px    -2px #362e41,
  1.5px  -2px #362e41,
  1px    -2px #362e41,
  0.5px  -2px #362e41,
  0px    -2px #362e41,
  -0.5px -2px #362e41,
  -1px   -2px #362e41,
  -1.5px -2px #362e41;
    width: 80%;
    height: 60%;
    position: absolute;
    top: 15%;
    padding: 5%;
    overflow: hidden;
}