

#ticket_button {
    border:  none;
    padding: 3%;
    padding-left: 3.5%;
    padding-top: 4%;
    box-shadow: 0px 0px 12px 6px #EE2E31;
    color: #F49D37;
    text-decoration: none;
    background-color: rgba(49, 47, 55, 0.45);
    border-radius: 7px;
    width: 50%;
    height: 100%;
    z-index: 1;
    transition: all 0.2s ease-in;
    text-align: center;
}

#festival_button {
  border:  none;
  padding: 3%;
  padding-left: 3.5%;
  box-shadow: 0px 0px 12px 6px #f27b31eb;
  color: #F49D37;
  cursor: pointer;
  text-decoration: none;
  background-color: rgba(49, 47, 55, 0.45);
  border-radius: 7px;
  z-index: 99;
  transition: all 0.2s ease-in;
}

#festival_button:hover {
  box-shadow: 0px 0px 18px 12px #f27b31eb;
  transform: scale(1.2, 1.2);
}

.icons {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.icons > a {
  margin: 5% 5%;
}

.info_text {
  margin-bottom: 5%;
  width: 100vw;
  z-index: 10;
  top: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.2em;
  line-height: 1.4em;
  //box-shadow:0px 0px 6px 3px #d6cfba;
  //background-color: rgba(0, 0, 0, 0.45);
  //background-color: rgba(49, 47, 55, 0.45);
  //background-color: rgb(49, 47, 55);
  border-radius: 5px;
  
  scrollbar-width: none; /* for Firefox */
}

#winterschlaf {
  font-family: "RussianFont";

}


#ticket_button:hover {
  box-shadow: 0px 0px 12px 12px #EE2E31;
  background-color: rgba(49, 47, 55, 0.4);
  transform: scale(1.2, 1.2);
}



.ticket_button_class {
    display: flex;
    width: 100vw;
    margin-top: 5%;
    margin-bottom: 10%;
    justify-content: center;
    align-items: center;
    z-index: 1;

}




.infopage_container {
    width: 100%;
    height: 100%;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll; 
}

@font-face {
  font-family: 'RussianFont';
  src: url('./../../Russian.ttf') format('truetype');
}

@font-face {
  font-family: "SpecialEliteRegular";
  src: url("./../../SpecialEliteRegular.ttf") format("truetype");
}

.infopage_info > * {
  opacity: 1;
}

.infopage_info > h1 {
  font-family: "RussianFont";
  color: #1D7874;
  font-size: 4rem;
  
}



.infopage_info {
  display: flex;
  flex-direction: column;
  font-family: 'SpecialEliteRegular';
  margin-top: 20%;
  
  
  
  /* font-family: 'Cinzel'; */
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 10;
  opacity: 100%;
  text-align: center;
  font-size: 1.7rem;
  letter-spacing: 0.1em;
  scrollbar-width: none;
  opacity: 1;
  background: transparent;
  color: #E0DDCF;
  text-shadow: -2px   -2px #362e41,
  -2px -1.5px #362e41,
  -2px   -1px #362e41,
  -2px -0.5px #362e41,
  -2px    0px #362e41,
  -2px  0.5px #362e41,
  -2px    1px #362e41,
  -2px  1.5px #362e41,
  -2px    2px #362e41,
  -1.5px  2px #362e41,
  -1px    2px #362e41,
  -0.5px  2px #362e41,
  0px     2px #362e41,
  0.5px   2px #362e41,
  1px     2px #362e41,
  1.5px   2px #362e41,
  2px     2px #362e41,
  2px   1.5px #362e41,
  2px     1px #362e41,
  2px   0.5px #362e41,
  2px     0px #362e41,
  2px  -0.5px #362e41,
  2px    -1px #362e41,
  2px  -1.5px #362e41,
  2px    -2px #362e41,
  1.5px  -2px #362e41,
  1px    -2px #362e41,
  0.5px  -2px #362e41,
  0px    -2px #362e41,
  -0.5px -2px #362e41,
  -1px   -2px #362e41,
  -1.5px -2px #362e41;
  overflow: hidden;
}

.infopage_info > .reading_background {
  opacity: 1;
  z-index: 20;
  color: rgba(172, 144, 244, 0.4);
  font-size: 3rem;
  //background-color: rgba(49, 47, 55, 0.4); let oldPurpleIshColor = (20, 15, 45);
}

.line-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

svg {
  display: inline-block;
  height: 100%;

}
 


.infopage_info > h1 {
  font-family: "RussianFont";
  font-size: 4rem;
  opacity: 1;
  
}

.infopage_info > a {
  color: rgb(109, 177, 191);
  opacity: 1;
}

.privacy_impressum_links {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: space-around;
  padding-left: 5%;
  margin-top: 5%;
  z-index: 1;
}

.privacy_impressum_links > a {
  text-decoration: none;
  color: rgba(244, 157, 55, 0.5);
  margin-top: 2%;
}

canvas {
  position: fixed !important;
}

#infoctr_sketch {
  opacity: 1;
  z-index: -1;
  background-color: transparent;
}

ul.dashed {
  list-style-type: none;
}

ul.dashed > li {
  margin: 2%;
  
}

.dashed > li > a {
  text-decoration: none;
  color: #E0DDCF;
}

.hover_menu:hover {
  cursor: pointer
}

ul.dashed > li:hover {
  transform: scale(1.2,1.2);
  cursor: pointer;
  transition: all 0.2s ease;
}

@media screen and (max-width: 900px){
  .infopage_info {
   
    font-size: 1em;
    margin-top: 10%;
    max-width: 100vw;
  }

  #infoctr_sketch {
    opacity: 1;
    display: flex;;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.moving_icons {
  position: relative;
}

svg {
  display: block;
  max-width: 100vw;
}

.smaller_text {
  font-size: small !important;
}