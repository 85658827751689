.header {
    cursor: pointer;
}

.header:hover {
    color: #F49D37;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.form-hidden {
    visibility: hidden;
    display: none;
}

.form_input > input {
    height: 2em;
    width: 50%;
    margin-top: 2%;
    margin-bottom: 2%;
}

.form_input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@keyframes appearAnimation {
    from {
      opacity: 0;
      visibility: hidden;
    }
    to {
      opacity: 100;
      visibility: visible;
    }
  }

  @keyframes disappearAnimation {
    from {
      opacity: 100;
      visibility: visible;
    }
    to {
      opacity: 0;
      visibility: hidden;
    }
  }

  #form-container {
    display: flex;
  }

  #submit_button {
    color: white;
    font-family: 'SpecialEliteRegular';
    font-size: 2rem;
    width: 30%;
    height: 5rem;
    background: #140f2d;
    box-shadow:0px 0px 12px 6px #EE2E31;
    border: none;
  }

  #submit_button:hover {
    box-shadow: 0px 0px 6px 3px #EE2E31;
    cursor: pointer;
  }

  .form_input > input:focus {
    outline: none;
  }


.form_inputs {
    opacity: 0;
    visibility: hidden;
}


.appear {
    -webkit-animation-name: appearAnimation;
    -webkit-animation-duration: 3s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: ease;
    -webkit-animation-fill-mode: forwards;

    animation-name: appearAnimation;
    animation-duration: 3s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-fill-mode: forwards;

    opacity: 100%;
    visibility: visible;
}

.disappear {
    -webkit-animation-name: disappearAnimation;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: ease;

    animation-name: disappearAnimation;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-timing-function: ease;

    

    opacity: 0%;
    visibility: hidden;
    display: none;
}

input[type="text"], input[type="email"]  {
  font-family: "SpecialEliteRegular";
  font-size: 0.67em;
  background-color: #E0DDCF;
}

#form_container {
  
  
  padding-top: 5%;
  padding-bottom: 5%;
  margin: 5%;
  border-radius: 10px;
  z-index: 1;
}

.smaller_text {
  font-size: small;
}



