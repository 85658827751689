.privacy_container {
    background: #140f2d;
    position: fixed;
    padding: 0;
    margin: 0;
    top: 21%;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.privacy_info {
  overflow: scroll;
  width: 80%;
  color: #E0DDCF;
  text-shadow: -2px   -2px #362e41,
  -2px -1.5px #362e41,
  -2px   -1px #362e41,
  -2px -0.5px #362e41,
  -2px    0px #362e41,
  -2px  0.5px #362e41,
  -2px    1px #362e41,
  -2px  1.5px #362e41,
  -2px    2px #362e41,
  -1.5px  2px #362e41,
  -1px    2px #362e41,
  -0.5px  2px #362e41,
  0px     2px #362e41,
  0.5px   2px #362e41,
  1px     2px #362e41,
  1.5px   2px #362e41,
  2px     2px #362e41,
  2px   1.5px #362e41,
  2px     1px #362e41,
  2px   0.5px #362e41,
  2px     0px #362e41,
  2px  -0.5px #362e41,
  2px    -1px #362e41,
  2px  -1.5px #362e41,
  2px    -2px #362e41,
  1.5px  -2px #362e41,
  1px    -2px #362e41,
  0.5px  -2px #362e41,
  0px    -2px #362e41,
  -0.5px -2px #362e41,
  -1px   -2px #362e41,
  -1.5px -2px #362e41;
  padding-top: 10%;
  margin-top: 5%;
  z-index: 10;
  opacity: 1;
  text-align: left;
  font-size: 1rem;
  letter-spacing: 0.05em;
  font-family: "SpecialEliteRegular";
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 
}


.privacy_info::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
