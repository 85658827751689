
#nav-item {
    text-decoration: none;
    font-size: 2rem;
    color: rgba(228, 177, 222, 0.85);
    z-index: 9999;
}

#nav-item:hover {
  color: rgb(225, 101, 101);
}
  
.logo-div {
    filter: invert(0);
    z-index: 9901;
    padding: 0;
    margin: 0;
}

.toggleBox {
  z-index: 9901;
  margin-right: 10%;
}
  
  
  
  img.close-menu-button {
    display: none;
    filter: invert(0.7);
    z-index: 9901;
    width: 70%;
  }

  img.menu-button {
      display: block;
      filter: invert(0.2);
      scale: 0.85;
      z-index: 9901;
      width: 70%;
  }

.nav-logo {

    width: 50%;
    height: auto;
    filter: invert(1);
    z-index: 9901;
  }
  
  :root {
    --clr-one: rgba(49, 47, 55, 0.45);
  }
  
  .header-bar {
    background: var(--clr-one);
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    display: flex;
    padding: 1%;
    justify-content: space-between;
    align-items: center;
  }
  
  .navigation {
    display: flex;
    flex-grow: 2;
    justify-content: space-around;
    align-items: center;

  }

  

  @media (max-width: 1000px) {

    .header-bar {
        
    }
    

    .navigation {
        display: none;
        flex-grow: 2;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(100, 90, 150, 0.85);
        z-index: 9900;
      }

      .logo-div
      {
        position: relative;
        left: 0;
        width: 48%;
      }

      
}

  .mobileNavigation {

  }
